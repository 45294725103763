import './App.css';
import {AppleAppStoreLink, GooglePlayStoreLink} from "./components/appStoreLinks";

function App() {
    return (
        <div className="App">
            <p>Redirecting you to the ZenSim app...</p>
            <p style={{marginBottom: '40px'}}><a href='zensim://'>Click here</a> if this doesn't happen automatically.</p>
            <p>Dont have the ZenSim app?</p>
            <div style={{display: 'flex', gap: 8, justifyContent: 'center'}}>
                <GooglePlayStoreLink />
                <AppleAppStoreLink />
            </div>
        </div>
    );
}

export default App;
